<template>
  <!--入离时间组件-->
  <section class="bottom_tip d_f">
    <img src="../img/hod_time.png" alt="">
    <div>
      <div class="tip_title">入离时间</div>
      <div class="tip_grey">
        <div>当日18:00前办理入住：请携带相关证件办理</div>
        <div>次日12:00前办理退房：续住或存放行李请联系酒店前台</div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "time_tip",
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style scoped lang="scss">

  .bottom_tip{
    .tip_grey{
      font-size:12px;
      font-weight:500;
      color:rgba(142,142,142,1);
      line-height:17px;
      >div{
        margin-bottom: 9px;
      }
    }
    .tip_title{
      font-size:13px;
      font-weight:500;
      color:rgba(62,62,62,1);
      line-height:17px;
      margin-bottom: 12px;
    }
    img{
      width: 13px;
      height: 13px;
      margin-right: 7px;
      position: relative;
      top: 1px;
    }
    background-color: #f0f0f0;
    padding: 18px 22px;
    font-size:12px;
    font-weight:500;
    color:rgba(62,62,62,1);
  }
</style>
